/*
 * 2021 Dark + Light mode theme colors
 */

export const black = "#2E3A47";

export const night = "#121212";

export const white = "#FFFFFF";

// Greens
export const green900 = "#0F3D26";
export const green800 = "#244C38";
export const green700 = "#275E43";
export const green600 = "#307553";
export const green500 = "#86B09B";
export const green400 = "#B6CFC2";
export const green300 = "#D1E0D9";
export const green200 = "#EBF2EF";
export const green100 = "#FAFFFC";

// Blues
export const blue900 = "#143657";
export const blue800 = "#194673";
export const blue700 = "#21588F";
export const blue600 = "#3971A8";
export const blue500 = "#5D96CF";
export const blue400 = "#88B1D9";
export const blue300 = "#C0D8F0";
export const blue200 = "#E6F1FC";
export const blue100 = "#F8FBFF";

// Reds
export const red900 = "#68182A";
export const red800 = "#800D25";
export const red700 = "#980B29";
export const red600 = "#AF2645";
export const red500 = "#C6516A";
export const red400 = "#E296A6";
export const red300 = "#F0C4CD";
export const red200 = "#FEEEF2";
export const red100 = "#FFF7F9";

// Purple
export const purple900 = "#17043E";
export const purple800 = "#2E1957";
export const purple700 = "#3B2566";
export const purple600 = "#533E7D";
export const purple500 = "#8471AB";
export const purple400 = "#B9ABD5";
export const purple300 = "#D7CEE9";
export const purple200 = "#F2ECFE";
export const purple100 = "#FCFAFF";

// Orange
export const orange900 = "#693A11";
export const orange800 = "#784213";
export const orange700 = "#8E4D14";
export const orange600 = "#B4631D";
export const orange500 = "#CF8545";
export const orange400 = "#E7B88F";
export const orange300 = "#F3D8C0";
export const orange200 = "#FFF3E8";
export const orange100 = "#FFFAF5";

// Yellow
export const yellow900 = "#E5A500";
export const yellow800 = "#EDAB00";
export const yellow700 = "#F7B200";
export const yellow600 = "#FFC121";
export const yellow500 = "#FCCB4C";
export const yellow400 = "#FCD97E";
export const yellow300 = "#FCE7B1";
export const yellow200 = "#FCF3DD";
export const yellow100 = "#FAF8F2";

// Grays
export const gray900 = "#212A33";
export const gray800 = "#2E3A47";
export const gray700 = "#36485C";
export const gray600 = "#475B70";
export const gray500 = "#596D84";
export const gray400 = "#C8D3E0";
export const gray300 = "#E6EDF5";
export const gray200 = "#F5F7FA";
export const gray100 = "#FCFEFF";

export const facebookBlue = "#3C5A99";
export const twitterBlue = "#1DA1F2";
export const linkedInBlue = "#2867B2";
