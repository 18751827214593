import { css } from "@emotion/react";

export const loadingSpinner = color => css`
  &:after {
    animation: spinAround 0.5s infinite linear;
    border: 2px solid ${color};
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: none;
    height: 1rem;
    position: relative;
    width: 1rem;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    position: absolute !important;
  }
`;
