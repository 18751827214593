import { createContext } from "react";

type ThemeTypes = "light" | "dark";

interface AviaryThemeData {
  setTheme: (theme: ThemeTypes) => void;
  isDS4Visuals: boolean;
  isDS3Visuals: boolean;
}

const AviaryThemeContext = createContext<AviaryThemeData>(null);

export type { AviaryThemeData, ThemeTypes };
export { AviaryThemeContext };
