import { css } from "@emotion/react";

import { layers } from "@styles";

export const base = css`
  position: fixed;
  bottom: 4.5rem;
  right: 2rem;
  z-index: ${layers.indexModalMax};
`;
