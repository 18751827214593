/**
 * StorePlatform indicates which application platform the user is using.
 * eg fullscript.com or emersonecologics.com
 */
enum StorePlatform {
  FULLSCRIPT = "Fullscript",
  EMERSON = "Emerson",
}

export { StorePlatform };
