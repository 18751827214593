import type { ReactNode } from "react";
import { useEffect, useRef, useState } from "react";

import type { SnackType, SnackPosition } from "../SnackbarContext";
import { SnackbarContext } from "../SnackbarContext";

interface Props {
  /**
   * Content to render
   *
   * @default undefined
   */
  children?: ReactNode;
}

const SnackWrap = ({ children }: Props) => {
  const [isSnackbarOpen, setSnackbarIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState<SnackType>();
  const [position, setPosition] = useState<SnackPosition>("bottomLeft");

  const timeout = useRef<number>();

  const setSnackbarState = (
    newStatus: SnackType,
    newMessage: string,
    isOpen: boolean,
    newPosition?: SnackPosition
  ) => {
    setStatus(newStatus);
    setMessage(newMessage);
    setSnackbarIsOpen(isOpen);
    setPosition(newPosition || "bottomLeft");
  };

  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  const setSnackMessage = (
    newStatus: SnackType,
    newMessage: string,
    newPosition?: SnackPosition
  ) => {
    clearTimeout(timeout.current);
    setSnackbarState(newStatus, newMessage, true, newPosition);
  };

  const closeSnack = (newMessage: string, noDelay?: boolean) => {
    if (noDelay) {
      setSnackbarState("normal", newMessage, false, position);
    } else {
      setSnackbarState("normal", newMessage, true, position);
      timeout.current = window.setTimeout(() => {
        setSnackbarState("normal", newMessage, false, position);
      }, 600);
    }
  };

  return (
    <SnackbarContext.Provider
      value={{
        isSnackbarOpen,
        closeSnack,
        message,
        status,
        setSnackMessage,
        position,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackWrap };
