export const instant = "0ms";
export const ultraFast = "50ms";
export const veryFast = "150ms";
export const fast = "200ms";
export const moderate = "300ms";
export const moderatelySlow = "400ms";
export const slow = "500ms";
export const verySlow = "700ms";
export const ultraSlow = "1400ms";
export const defaultTiming = fast;
