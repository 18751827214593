import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens";
import { typography, borders } from "aviary-tokens";

import { animations, baseStyles, helpers, timing, utilities } from "@styles";

export const button = {
  noStyle: css`
    appearance: none;
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: inherit;
    cursor: pointer;
  `,
  base: (theme: Theme) => css`
    ${baseStyles.controlBase}
    ${utilities.transition}
    justify-content: center;
    border-radius: ${borders.radiusBase};

    path {
      transition: color ${timing.moderatelySlow} ${animations.easeOutCirc};
    }

    cursor: pointer;
    border-color: ${theme.primary.borderBase};
    font-weight: ${typography.weightStrong};
    white-space: nowrap;
    height: 40px;

    padding: 6px 16px;

    ${helpers.loadingSpinner(theme.text.onBackground)}

    &[disabled] {
      opacity: 0.9;
      user-select: none;
      pointer-events: none;

      color: ${theme.disabled.text};
      background-color: ${theme.disabled.background};
      border-color: ${theme.disabled.border};

      .fa-spinner-third path,
      path {
        fill: ${theme.disabled.text};
      }
    }
  `,
  xsmall: (theme: Theme) => css`
    height: 28px;
    padding: 0 8px;
    font-size: ${theme.aviaryTypography.footnote.fontSize};
  `,
  small: css`
    height: 36px;
    padding: 0 12px;
  `,
  large: css`
    height: 56px;
    padding: 16px;
  `,
  fullwidth: css`
    width: 100%;
  `,
};

export const circular = css`
  border-radius: 50%;
`;

const lightActionStyles = {
  hover: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundHover};
    border-color: ${theme.light.backgroundHover};
  `,
  active: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundActive};
    border-color: ${theme.light.backgroundActive};
  `,
  svgFill: (theme: Theme) => css`
    &&&& {
      .svg-inline--fa path:not(.fa-spinner-third),
      path {
        fill: ${theme.text.onBackground};
      }
    }
  `,
  svgFillContrast: (theme: Theme) => css`
    &&& {
      .svg-inline--fa path:not(.fa-spinner-third),
      path {
        fill: ${theme.light.textBase};
      }
    }
  `,
};

export const lightStyles = {
  base: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundBase};
    border-color: ${theme.light.backgroundBase};

    &:hover {
      ${lightActionStyles.hover(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
    }
  `,
  outlined: (theme: Theme) => css`
    color: ${theme.text.onBackground};
    background-color: transparent;
    &&& {
      border-color: ${theme.text.onBackground};
    }
    ${lightActionStyles.svgFill(theme)}

    &:hover {
      ${lightActionStyles.hover(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
    }
  `,
  isText: (theme: Theme) => css`
    color: ${theme.text.onBackground};
    background-color: transparent;
    border-color: transparent;
    ${lightActionStyles.svgFill(theme)}

    &:hover {
      ${lightActionStyles.hover(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }
  `,
  isIcon: (theme: Theme) => css`
    ${lightActionStyles.svgFillContrast(theme)}

    &:hover,
    &:active {
      ${lightActionStyles.svgFillContrast(theme)}
    }
  `,
};

export const loadingStyles = css`
  color: transparent !important;
  pointer-events: none;
  user-select: none;

  &&&& {
    color: transparent;
  }

  svg:not(.fa-spinner-third) {
    visibility: hidden;
  }
`;

/*
 * Loader Icon Styles
 */
export const loader = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const loaderIcon = css`
  animation-duration: ${timing.slow};
  display: block;
`;

export const normalSVG = (theme: Theme) => css`
  path {
    fill: ${theme.text.onBackground};
  }
`;

export const baseIconStyles = css`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const iconStyles = {
  xsmall: css`
    height: 28px;
    width: 28px;
  `,
  small: css`
    height: 36px;
    width: 36px;
  `,
  large: css`
    height: 48px;
    width: 48px;
  `,
};

/*
 * Latch Styling, utilized with button groups
 */
const notLeftLatch = color => css`
  border-right: none;
  &:after {
    position: absolute;
    right: 0;
    content: "";
    background-color: ${color};
    height: 1.5rem;
    width: 1px;
    margin-right: -1px;
  }
`;
const notRightLatch = color => css`
  border-left: none;
  &:before {
    position: absolute;
    left: 0;
    content: "";
    background-color: ${color};
    height: 100%;
    width: 1px;
    margin-left: -1px;
  }
`;

const notRightLatchDisabled = (theme: Theme) => css`
  &:before {
    background-color: ${theme.disabled.border};
    width: 1px;
  }
`;

export const latch = (profile: ColorProfileTheme, theme: Theme) => {
  return {
    left: css`
      ${notRightLatch(theme.separator.base)};
      border-radius: 0 ${borders.radiusBase} ${borders.radiusBase} 0;
    `,
    middle: css`
      ${notLeftLatch(theme.separator.base)}
      ${notRightLatch(theme.separator.base)}
    border-radius: 0;
    `,
    right: css`
      ${notLeftLatch(theme.separator.base)};
      border-radius: ${borders.radiusBase} 0 0 ${borders.radiusBase};
    `,
    disabled: css`
      ${notRightLatchDisabled(theme)}
    `,
  };
};

export const intentionStyles = {
  primary: (theme: Theme) => css`
    color: ${theme.primary.textBase};

    .fa-spinner-third path,
    path {
      fill: ${theme.primary.textBase};
    }
    background-color: ${theme.primary.backgroundBase};
    border-color: ${theme.primary.backgroundBase};

    &:hover {
      color: ${theme.primary.textBase};
      background-color: ${theme.primary.backgroundHover};
      border-color: ${theme.primary.backgroundHover};
    }

    &:active {
      path {
        fill: ${theme.primary.textBase};
      }
      background-color: ${theme.primary.backgroundActive};
      border-color: ${theme.system.borderFocused};
    }
  `,
  secondary: (theme: Theme) => css`
    color: ${theme.secondary.textBase};
    background-color: ${theme.secondary.backgroundBase};
    border-color: ${theme.secondary.borderBase};

    .fa-spinner-third path,
    path {
      fill: ${theme.secondary.textBase};
    }

    &:hover {
      color: ${theme.secondary.textHover};
      background-color: ${theme.secondary.backgroundHover};
      border-color: ${theme.secondary.borderHover};
    }

    &:active {
      path {
        fill: ${theme.secondary.textBase};
      }
      color: ${theme.secondary.textActive};
      background-color: ${theme.secondary.backgroundActive};
      border-color: ${theme.system.borderFocused};
    }
  `,
  system: (theme: Theme) => css`
    color: ${theme.system.textLabelEmphasis};
    background-color: ${theme.system.backgroundBase};
    border-color: ${theme.system.borderBase};

    .fa-spinner-third path,
    path {
      fill: ${theme.system.textBase};
    }

    &:hover {
      background-color: ${theme.system.backgroundMuted};
      border-color: ${theme.system.borderHover};
      color: ${theme.system.textLabelEmphasis};
    }

    &:active {
      background-color: ${theme.system.backgroundMutedHover};
      border-color: ${theme.system.borderActive};
      color: ${theme.system.textLabelEmphasis};
    }
  `,

  danger: (theme: Theme) => css`
    color: ${theme.danger.textBase};
    background-color: ${theme.system.backgroundBase};
    border-color: ${theme.danger.borderBase};

    .fa-spinner-third path,
    path {
      fill: ${theme.danger.textBase};
    }

    &:hover {
      background-color: ${theme.system.backgroundBase};
      border-color: ${theme.danger.borderHover};
      color: ${theme.danger.textHover};
    }

    &:active {
      background-color: ${theme.system.backgroundBase};
      border-color: ${theme.danger.borderActive};
      color: ${theme.danger.textActive};
    }
  `,

  textSuccess: (theme: Theme) => css`
    color: ${theme.success.textBase};
    background-color: transparent;
    border-color: transparent;

    .fa-spinner-third path,
    path {
      fill: ${theme.success.textBase};
    }

    &:hover {
      background-color: ${theme.success.backgroundMuted};
      color: ${theme.success.textHover};
      border-color: transparent;
    }

    &:active {
      background-color: ${theme.success.backgroundMutedHover};
      color: ${theme.success.textActive};
      border-color: transparent;
    }
  `,

  textSystem: (theme: Theme) => css`
    color: ${theme.text.emphasis};
    background-color: transparent;
    border-color: transparent;

    .fa-spinner-third path,
    path {
      fill: ${theme.system.textBase};
    }

    &:hover {
      background-color: ${theme.system.backgroundMuted};
      color: ${theme.system.textHover};
      border-color: transparent;
    }

    &:active {
      background-color: ${theme.system.backgroundMutedHover};
      color: ${theme.system.textActive};
      border-color: transparent;
    }
  `,

  textDanger: (theme: Theme) => css`
    color: ${theme.danger.textBase};
    background-color: transparent;
    border-color: transparent;

    .fa-spinner-third path,
    path {
      fill: ${theme.danger.textBase};
    }

    &:hover {
      background-color: ${theme.danger.backgroundMuted};
      color: ${theme.danger.textHover};
      border-color: transparent;
    }

    &:active {
      background-color: ${theme.danger.backgroundMutedHover};
      color: ${theme.danger.textActive};
      border-color: transparent;
    }
  `,

  lightFilled: (theme: Theme) => css`
    background-color: ${theme.light.backgroundBase};
    color: ${theme.light.textBase};
    border-color: ${theme.light.backgroundBase};

    .fa-spinner-third path,
    path {
      fill: ${theme.light.textBase};
    }

    &:hover {
      color: ${theme.light.textBase};
      background-color: ${theme.light.backgroundHover};
      border-color: ${theme.light.backgroundHover};
    }

    &:active {
      color: ${theme.light.textBase};
      background-color: ${theme.light.backgroundActive};
    }
  `,

  lightOutlined: (theme: Theme) => css`
    background-color: transparent;
    color: ${theme.text.onBackground};
    border-color: ${theme.system.borderBase};

    &:hover {
      background-color: ${theme.light.backgroundHover};
      color: ${theme.light.textBase};
      border-color: ${theme.light.backgroundHover};
    }

    &:active {
      background-color: ${theme.light.backgroundActive};
    }
  `,

  lightText: (theme: Theme) => css`
    background-color: transparent;
    color: ${theme.text.onBackground};
    border-color: transparent;

    &:hover {
      background-color: ${theme.light.backgroundHover};
      color: ${theme.light.textBase};
      border-color: ${theme.light.backgroundHover};
    }

    &:active {
      background-color: ${theme.light.backgroundActive};
      border-color: ${theme.light.backgroundActive};
    }
  `,
};
