import type { AviaryTheme } from "aviary-tokens/themes";
import { dark } from "aviary-tokens/themes";

import { useKeyCombo } from "@shared/hooks/useKeyCombo/useKeyCombo";
import { isProduction, isTest } from "@shared/utils/fullscriptEnv/fullscriptEnv";

import type { ThemeTypes } from "../AviaryThemeContext";

interface Props {
  controlledTheme: ThemeTypes;
  currentTheme: AviaryTheme;
  isEmerson: boolean;
  setTheme: (theme: ThemeTypes) => void;
}

const ThemeKeyboardShorcut = ({ controlledTheme, currentTheme, isEmerson, setTheme }: Props) => {
  // Dev-only shortcut to toggle between light and dark mode
  useKeyCombo({
    targetKeys: ["x"],
    withShift: true,
    disabled: isEmerson || isProduction() || isTest(),
    onPressed: () => {
      if (!controlledTheme) setTheme(currentTheme === dark ? "light" : "dark");
    },
  });

  return null;
};

export { ThemeKeyboardShorcut };
