import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens";
import { typography } from "aviary-tokens";

import { animations, baseStyles, dimensions, helpers, timing, utilities } from "@styles";
import { removeClickOutline } from "@styles/helpers";

export const button = {
  noStyle: css`
    appearance: none;
    border: none;
    background-color: transparent;
    text-align: left;
    font-size: inherit;
    cursor: pointer;
  `,
  base: (theme: Theme) => css`
    ${baseStyles.controlBase}
    ${utilities.transition}
    justify-content: center;

    path {
      transition: color ${timing.moderatelySlow} ${animations.easeOutCirc};
    }

    cursor: pointer;
    color: ${theme.text.onBackground};
    border-color: ${theme.primary.borderBase};
    font-weight: ${typography.weightStrong};
    white-space: nowrap;
    height: 2.5rem;

    padding-top: calc(0.375rem - ${dimensions.borderWidth});
    padding-bottom: calc(0.375rem - ${dimensions.borderWidth});
    padding-left: calc(1rem - ${dimensions.borderWidth});
    padding-right: calc(1rem - ${dimensions.borderWidth});

    &:hover {
      box-shadow: 0 1px 2px 0 ${helpers.hexToRgba(theme.input.borderActive, 0.25)};
    }

    ${removeClickOutline}

    ${helpers.loadingSpinner(theme.text.onBackground)}

    &[disabled] {
      font-weight: ${typography.weightBase};
      opacity: 0.9;
      user-select: none;
      box-shadow: none;
      pointer-events: none;
    }
  `,
  xsmall: css`
    height: 1.5rem;
    padding: 0 calc(0.5rem - ${dimensions.borderWidth});
  `,
  small: css`
    height: 2.25rem;
    padding-left: calc(0.75rem - ${dimensions.borderWidth});
    padding-right: calc(0.75rem - ${dimensions.borderWidth});
  `,
  large: css`
    height: 3.5rem;
    padding: calc(1rem - ${dimensions.borderWidth});
  `,
  fullwidth: css`
    width: 100%;
  `,
};

export const circular = css`
  border-radius: 50%;
`;

/*
 * Conditional Color application functions
 * -- Uses ColorProfileTheme
 */

export const normalStyles = (profile: ColorProfileTheme, theme: Theme) => {
  const hasBackgroundHover = (colorProfile: ColorProfileTheme) => {
    if ("backgroundHover" in colorProfile) return colorProfile.backgroundHover;

    return colorProfile.backgroundBase;
  };

  const hasBackgroundActive = (colorProfile: ColorProfileTheme) => {
    if ("backgroundActive" in colorProfile) return colorProfile.backgroundActive;

    return colorProfile.backgroundBase;
  };

  return css`
    background-color: ${profile.backgroundBase};
    border-color: ${profile.borderBase};

    &:hover {
      color: ${theme.text.onBackground};
      background-color: ${hasBackgroundHover(profile)};
      border-color: ${profile.borderHover};
    }

    &:active {
      background-color: ${hasBackgroundActive(profile)};
      border-color: ${profile.borderActive};
    }

    &[disabled] {
      color: ${theme.disabled.text};
      background-color: ${theme.disabled.background};
      border-color: ${theme.disabled.border};

      .fa-spinner-third path,
      path {
        fill: ${theme.disabled.text};
      }
    }

    &:after {
      border-width: ${dimensions.borderWidth};
      border-style: solid;
      border-color: ${theme.system.backgroundBase};
      border-right-color: transparent;
      border-top-color: transparent;
    }
  `;
};

export const outlinesStyles = (profile: ColorProfileTheme, theme: Theme) => css`
  color: ${profile.textBase};
  background-color: ${theme.system.backgroundBase};
  border-color: ${profile.borderBase};

  &:hover {
    .svg-inline--fa path:not(.fa-spinner-third) {
      fill: ${profile.textHover};
    }
  }

  &:active {
    .svg-inline--fa path:not(.fa-spinner-third) {
      fill: ${profile.textActive};
    }
  }

  &:hover {
    color: ${profile.textHover};
    background-color: ${theme.system.backgroundBase};
    border-color: ${profile.borderHover};
  }

  &:active {
    color: ${profile.textActive};
    background-color: ${theme.system.backgroundBase};
    border-color: ${profile.borderActive};
  }

  &:after {
    border: 2px solid ${profile.borderBase};
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

export const textTypeStyle = (profile: ColorProfileTheme) => css`
  color: ${profile.textBase};
  path {
    fill: ${profile.textBase};
  }
  background-color: transparent;
  border-color: transparent;

  &:hover {
    box-shadow: none;
    color: ${profile.textHover};
    background-color: ${profile.backgroundMuted};
    border-color: transparent;
  }

  &:active {
    color: ${profile.textActive};
    background-color: ${profile.backgroundMutedActive};
    border-color: transparent;
    box-shadow: 0 0 0 0.05rem ${helpers.hexToRgba(profile.backgroundMutedActive, 0.15)};
  }
`;

export const systemProfileStyles = (profile: ColorProfileTheme) => css`
  color: ${profile.textBase};

  &:hover {
    color: ${profile.textHover};
  }

  path:not(.fa-spinner-third) {
    fill: ${profile.textBase};
  }
`;

const lightActionStyles = {
  hover: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundHover};
    border-color: ${theme.light.backgroundHover};
  `,
  active: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundActive};
    border-color: ${theme.light.backgroundActive};
  `,
  svgFill: (theme: Theme) => css`
    &&&& {
      .svg-inline--fa path:not(.fa-spinner-third),
      path {
        fill: ${theme.text.onBackground};
      }
    }
  `,
  svgFillContrast: (theme: Theme) => css`
    &&& {
      .svg-inline--fa path:not(.fa-spinner-third),
      path {
        fill: ${theme.light.textBase};
      }
    }
  `,
};

export const lightStyles = {
  base: (theme: Theme) => css`
    color: ${theme.light.textBase};
    background-color: ${theme.light.backgroundBase};
    border-color: ${theme.light.backgroundBase};

    &:hover {
      ${lightActionStyles.hover(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
    }
  `,
  outlined: (theme: Theme) => css`
    color: ${theme.text.onBackground};
    background-color: transparent;
    &&& {
      border-color: ${theme.text.onBackground};
    }
    ${lightActionStyles.svgFill(theme)}

    &:hover {
      ${lightActionStyles.hover(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
    }
  `,
  isText: (theme: Theme) => css`
    color: ${theme.text.onBackground};
    background-color: transparent;
    border-color: transparent;
    ${lightActionStyles.svgFill(theme)}

    &:hover {
      ${lightActionStyles.hover(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }

    &:active {
      ${lightActionStyles.active(theme)}
      ${lightActionStyles.svgFillContrast(theme)}
    }
  `,
  isIcon: (theme: Theme) => css`
    ${lightActionStyles.svgFillContrast(theme)}

    &:hover,
    &:active {
      ${lightActionStyles.svgFillContrast(theme)}
    }
  `,
};

export const loadingStyles = css`
  color: transparent !important;
  pointer-events: none;
  user-select: none;

  &&&& {
    color: transparent;
  }

  svg:not(.fa-spinner-third) {
    visibility: hidden;
  }
`;

/*
 * Loader Icon Styles
 */
export const loader = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const loaderIcon = css`
  animation-duration: ${timing.slow};
  display: block;
`;

export const normalSVG = (theme: Theme) => css`
  path {
    fill: ${theme.text.onBackground};
  }
`;

export const textTypeSVG = (profile: ColorProfileTheme) => css`
  path {
    fill: ${profile.textBase};
  }
`;

export const outlineSVG = (profile: ColorProfileTheme) => css`
  path {
    fill: ${profile.textBase};
  }
`;

export const baseIconStyles = css`
  width: 2.5rem;
  height: 2.5rem;
  padding: 0 !important;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const iconStyles = {
  xsmall: css`
    height: 1.5rem;
    width: 1.5rem;
  `,
  small: css`
    height: 2.25rem;
    width: 2.25rem;
  `,
  large: css`
    height: 3rem;
    width: 3rem;
  `,
};

/*
 * Latch Styling, utilized with button groups
 */
const notLeftLatch = color => css`
  border-right: none;
  &:after {
    position: absolute;
    right: 0;
    content: "";
    background-color: ${color};
    height: 1.5rem;
    width: 1px;
    margin-right: -1px;
  }
`;
const notRightLatch = color => css`
  border-left: none;
  &:before {
    position: absolute;
    left: 0;
    content: "";
    background-color: ${color};
    height: 100%;
    width: 1px;
    margin-left: -1px;
  }
`;

const notRightLatchDisabled = (theme: Theme) => css`
  &:before {
    background-color: ${theme.disabled.border};
    width: 1px;
  }
`;

export const latch = (profile: ColorProfileTheme, theme: Theme) => {
  return {
    left: css`
      ${notRightLatch(theme.separator.base)};
      border-radius: 0 ${dimensions.borderRadius} ${dimensions.borderRadius} 0;
    `,
    middle: css`
      ${notLeftLatch(theme.separator.base)}
      ${notRightLatch(theme.separator.base)}
    border-radius: 0;
    `,
    right: css`
      ${notLeftLatch(theme.separator.base)};
      border-radius: ${dimensions.borderRadius} 0 0 ${dimensions.borderRadius};
    `,
    disabled: css`
      ${notRightLatchDisabled(theme)}
    `,
  };
};
