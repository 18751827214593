import { useContext } from "react";

import type { AviaryThemeData } from "./AviaryThemeContext";
import { AviaryThemeContext } from "./AviaryThemeContext";

const useSetAviaryTheme = (): AviaryThemeData => {
  return useContext(AviaryThemeContext);
};

export { useSetAviaryTheme };
