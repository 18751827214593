import { css, type Theme } from "@emotion/react";

import { animations, dimensions } from "@styles";

export const baseSkeleton = (theme: Theme) => css`
  ${animations.skeletonPulse(theme)};
  background-color: ${theme.system.borderBase};
  border-radius: ${dimensions.borderRadius};
`;

export const button = {
  base: (theme: Theme) => css`
    ${baseSkeleton(theme)};
  `,
  xsmall: css`
    width: 4rem;
    height: 1.5rem;
  `,
  small: css`
    width: 4rem;
    height: 2.25rem;
  `,
  medium: css`
    width: 6rem;
    height: 2.5rem;
  `,
  large: css`
    width: 8rem;
    height: 3.5rem;
  `,
};

export const floated = {
  left: css`
    float: left;
  `,
  right: css`
    float: right;
  `,
};

export const heading = {
  base: (theme: Theme) => css`
    ${baseSkeleton(theme)};
    min-width: 5rem;
    width: 30%;
  `,
  h1: (theme: Theme) => css`
    height: ${theme.aviaryTypography.h1.fontSize};
  `,
  h2: (theme: Theme) => css`
    height: ${theme.aviaryTypography.h2.fontSize};
  `,
  h3: (theme: Theme) => css`
    height: ${theme.aviaryTypography.h3.fontSize};
  `,
  h4: (theme: Theme) => css`
    height: ${theme.aviaryTypography.h4.fontSize};
  `,
  h5: (theme: Theme) => css`
    height: ${theme.aviaryTypography.h5.fontSize};
  `,
  body: (theme: Theme) => css`
    height: ${theme.aviaryTypography.body.fontSize};
  `,
  footnote: (theme: Theme) => css`
    height: ${theme.aviaryTypography.footnote.fontSize};
  `,
  caption: (theme: Theme) => css`
    height: ${theme.aviaryTypography.caption.fontSize};
  `,
};

export const image = {
  base: (theme: Theme) => css`
    ${baseSkeleton(theme)};
  `,
  tiny: css`
    height: 1rem;
    width: 1rem;
  `,
  small: css`
    height: 2rem;
    width: 2rem;
  `,
  medium: css`
    height: 2.5rem;
    width: 2.5rem;
  `,
  large: css`
    height: 4rem;
    width: 4rem;
  `,
  extraLarge: css`
    height: 12rem;
    width: 12rem;
  `,
  fill: css`
    height: 100%;
    width: 100%;
  `,
};

export const fullWidth = css`
  width: 100%;
`;

export const circular = css`
  border-radius: 100%;
`;

export const customWidth = (width: number) => css`
  width: ${width}rem;
`;

export const customHeight = (height: number) => css`
  height: ${height}rem;
`;

export const margin = {
  bottom: css`
    margin-bottom: 0.5rem;
  `,
  bottomLarge: css`
    margin-bottom: 1rem;
  `,
  bottomButton: css`
    margin-bottom: 1.5rem;
  `,
  top: css`
    margin-top: 0.5rem;
  `,
  topLarge: css`
    margin-top: 1rem;
  `,
  topButton: css`
    margin-top: 1.5rem;
  `,
  topAndBottom: css`
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  `,
  topAndBottomLarge: css`
    margin-bottom: 1rem;
    margin-top: 1rem;
  `,
};
