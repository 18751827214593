// Thumbs
export const thumbDimensions = "50px";
export const thumbDimensionsSmall = "35px";
export const thumbDimensionsLarge = "65px";

// Border
export const borderWidth = "1px";
export const borderRadius = "4px";
export const cardRadius = "8px";
export const circleRadius = "50%";
export const modalBorderRadius = "8px";
export const roundedCardRadius = "14px";
export const pillBorderRadius = "80px";
export const accountCard = "5px 5px 0 0";
export const semiRoundedCardRadius = "10px";

export const containerMargin = "2rem";
export const containerMarginMobile = "1rem";

export const GAP = 32;

export const breakpoints = {
  phoneMini: { min: 0, max: 319 },
  phoneSmall: { min: 320, max: 374 },
  phone: { min: 375, max: 599 },
  phoneLarge: { min: 600, max: 767 },
  tablet: { min: 768, max: 959 + 2 * GAP }, // 768px - 1023px
  desktop: { min: 960 + 2 * GAP, max: 1151 + 2 * GAP }, // 1024px - 1215px
  widescreen: { min: 1152 + 2 * GAP, max: 1215 + 2 * GAP }, // 1216px - 1279px
  fullHD: { min: 1216 + 2 * GAP, max: Number.MAX_SAFE_INTEGER }, // 1280px - infinity
};

export const heightBreakpoints = {
  mobile640: { min: 300, max: 640 },
  mobile780: { min: 641, max: 780 },
  desktop720: { min: 640, max: 720 },
  desktop1080: { min: 721, max: 1080 },
  desktop4k: { min: 1081, max: 2160 },
};

export const mobile640Height = `${heightBreakpoints.mobile640.min}px`;
export const mobile640HeightMax = `${heightBreakpoints.mobile640.max}px`;

export const mobile780Height = `${heightBreakpoints.mobile780.min}px`;
export const mobile780HeightMax = `${heightBreakpoints.mobile780.max}px`;

export const desktop720Height = `${heightBreakpoints.desktop720.min}px`;
export const desktop720HeightMax = `${heightBreakpoints.desktop720.max}px`;

export const desktop1080Height = `${heightBreakpoints.desktop1080.min}px`;
export const desktop1080HeightMax = `${heightBreakpoints.desktop1080.max}px`;

export const desktop4kHeight = `${heightBreakpoints.desktop4k.min}px`;
export const desktop4kHeightMax = `${heightBreakpoints.desktop4k.max}px`;

// Bulma default breakpoints
export const gap = `${GAP}px`;

export const maxContainer = `${breakpoints.fullHD.min}px`;

// New method of breakpoints, all values are INCLUSIVE and never cross into another "named" breakpoint.
// EX: "tablet" or "tabletMax" only ever reference values that are valid tablet sizes ( ie: between 769px and 1023px)

// Phone mini
export const phoneMini = `${breakpoints.phoneMini.min}px`;
export const phoneMiniMax = `${breakpoints.phoneMini.max}px`;

// Phone small 320px (iPhone SE, 5S)
export const phoneSmall = `${breakpoints.phoneSmall.min}px`;
export const phoneSmallMax = `${breakpoints.phoneSmall.max}px`;

// Phone 375px (iPhone 6S, 7, 8 etc)
export const phone = `${breakpoints.phone.min}px`;
export const phoneMax = `${breakpoints.phone.max}px`;

// Phone large 600px
export const phoneLarge = `${breakpoints.phoneLarge.min}px`;
export const phoneLargeMax = `${breakpoints.phoneLarge.max}px`;

// Tablet 768px
export const tablet = `${breakpoints.tablet.min}px`;
export const tabletMax = `${breakpoints.tablet.max}px`;

// Desktop 1024px
export const desktop = `${breakpoints.desktop.min}px`;
export const desktopMax = `${breakpoints.desktop.max}px`;

// Widescreen 1216px
export const widescreen = `${breakpoints.widescreen.min}px`;
export const widescreenMax = `${breakpoints.widescreen.max}px`;

// FullHD 1408px+
export const fullHD = `${breakpoints.fullHD.min}px`;
export const fullHDMax = `${breakpoints.fullHD.max}px`;
