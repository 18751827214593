/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
const POLYFILL_SRCS = "PolyfillSrcs";

const loadPolyfillSrcs = (srcs: string[], onLoad: () => void) => {
  let count = srcs.length;
  if (!srcs || srcs.length === 0) return onLoad();

  for (let i = 0; i < srcs.length; i++) {
    const scriptNode = document.createElement("script");
    scriptNode.src = srcs[i];
    scriptNode.onload = function () {
      count -= 1;
      if (count <= 0) onLoad();
    };
    document.head.appendChild(scriptNode);
  }
};

export { loadPolyfillSrcs, POLYFILL_SRCS };
