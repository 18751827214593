const e2eAttributes = {
  rails: {
    // These are used on the Rails side of the application, before modifying them, please check they're not referenced anywhere
    fsjs: {
      selectStoreOwnerRole: "select-store-owner-role",
    },
    fsjsTesterLink: "fsjs-tester-link",
    retailOrders: "retail-orders-link",
    wholesaleOrders: "wholesale-orders-link",
    mainMenu: "main-menu",
    orderSearch: "admin-order-search",
    userDropdown: "admin-user-link",
    signOut: "admin-user-sign-out-link",
    navSearch: "nav-search",
    impersonateMenu: "impersonate-link",
    impersonateDemoStoreLink: "impersonate-link-practitioner-fullscript",
    impersonateDemoClerkLink: "impersonate-link-clerk-fullscript",
    impersonateDemoPatientLink: "impersonate-link-patient-fullscript",
    adminDashboard: "admin-dashboard",
    storePage: {
      editModality: "edit-modality",
      modalityDropdown: "modality-dropdown",
      modalitySubmit: "modality-submit",
      rewardsLink: "rewards-link",
    },
  },
  admin: {
    impersonateModalLink: "impersonation-modal-link",
    reasonList: "reason-list",
    impersonateSubmitButton: "impersonate-submit-button",
  },
  practitionerHeader: {
    accountSettings: "practitioner_account_settings",
    dropdownLinkSignOut: "dropdownlink_signout",
    staffSettings: "staff-settings-link",
    wholesalePolicies: "wholesale-policies-link",
  },
  closeModalButton: "close-modal-button",
  searchProducts: "search-products",
  filtersButton: "filters-button",
  brandsFilter: "brands-filter",
  availabilityFilter: "availability-filter",
  aviarySkeleton: "aviary-skeleton",
  productName: "product-name",
  attachment: "attachment",
  lexicalEditorContentEditable: "lexical-editor-content-editable",
  confirmModalPrimaryButton: "confirm-modal-primary-button",
  viewCartButton: "view-cart-button",
  addProductToCart: "add-product-to-cart",
  msrp: "msrp",
  filterByBrand: (name: string) => `${name} filter`,
  inStockFilter: "in-stock-filter",
  buildPlan: "build-plan",
  cart: {
    totalPrice: "cart-total-price",
    subtotalNew: "cart-subtotal-new",
    shipping: "cart-shipping",
    taxes: "cart-taxes",
  },
  emptyState: "empty-state",
  officeSignUp: {
    password: "office-sign-up-password",
    button: "office-sign-up-button",
    modal: "office-sign-up-modal",
  },
  paymentSettings: {
    streetAddressSuggestionContainer: "street-address-suggestions-container",
    streetAddressSuggestion: "street-address-suggestion",
    businessType: "business-type",
    businessName: "business-name",
    employerId: "employer-id",
    ssn: "ssn",
    routingNumber: "routing-number",
    accountNumber: "account-number",
    accountHolder: "account-holder",
    streetAddress: "street-address",
  },
} as const;

export { e2eAttributes };
