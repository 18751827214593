import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { dimensions } from "@styles";

export const normalize = (theme: Theme) => css`
  :root[data-theme="dark"] {
    color-scheme: dark;
  }
  :root[data-theme="light"] {
    color-scheme: light;
  }
  html {
    font-size: ${theme.aviaryTypography.body.fontSize};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;
    text-size-adjust: 100%;
    box-sizing: border-box;
  }
  /* adds an extra footer-type spacer when sticky footer is on page */
  /* data attr added to html element in @aviary/layouts/StickyFooter*/
  /* div with data attr in @aviary/layouts/PageFooter */
  html[data-footer="sticky"] footer > div[data-footer="sticky"] {
    height: 4rem;
  }
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: ${typography.weightBase};
  }
  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }
  button,
  input,
  select,
  textarea {
    margin: 0;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td,
  th {
    padding: 0;
    text-align: left;
  }
  ul {
    list-style: none;
  }
  img,
  embed,
  iframe,
  object,
  video {
    height: auto;
    max-width: 100%;
  }
  iframe {
    border: 0;
  }
`;

export const aviaryBaseStyles = (theme: Theme) => css`
  body,
  html {
    background-color: ${theme.surface.level0};
  }
  body {
    color: ${theme.text.body};
    font-size: ${theme.aviaryTypography.body.fontSize};
    font-weight: ${typography.weightBase};
    line-height: 1.5;

    width: 100vw;
    max-width: 100%;
    height: auto;
    background-color: ${theme.surface.level0};

    /* iOS devices selector */
    @supports (-webkit-overflow-scrolling: touch) {
      height: auto;
    }
    @media only screen and (max-width: ${dimensions.phoneSmall}) {
      height: auto;
    }
  }
  article,
  aside,
  figure,
  footer,
  header,
  hgroup,
  section {
    display: block;
  }

  html,
  body,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: ${typography.fontFamilySansSerif};
  }

  a {
    color: ${theme.success.textBase};
    cursor: pointer;
    text-decoration: none;
    overflow-wrap: anywhere;

    &:hover {
      color: ${theme.success.textHover};
    }
    &:active {
      color: ${theme.success.textActive};
    }
  }

  img {
    height: auto;
    max-width: 100%;
  }

  input[type="checkbox"],
  input[type="radio"] {
    vertical-align: baseline;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    td,
    th {
      vertical-align: top;
    }
  }

  strong {
    color: ${theme.text.emphasis};
    font-weight: ${typography.weightSemiBold};
  }

  span {
    font-style: inherit;
    font-weight: inherit;
  }

  hr {
    background-color: ${theme.separator.base};
    border: none;
    display: block;
    height: 2px;
    margin: 1.5rem 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const whiteBackground = (theme: Theme) => css`
  body,
  html {
    background-color: ${theme.surface.level1};
  }
`;
