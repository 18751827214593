const isModernBrowser = (): boolean => {
  return (
    window.Promise &&
    window.fetch &&
    window.Symbol &&
    (window as any).ResizeObserver &&
    (window as any).IntersectionObserver &&
    Array.prototype.at &&
    !!Intl?.ListFormat
  );
};

export { isModernBrowser };
