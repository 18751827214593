/* eslint-disable import/export, no-restricted-imports */
import {
  FontAwesomeIcon as OriginalFontAwesomeIcon,
  type FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { useId } from "react";

/**
 * A wrapper around the original FontAwesomeIcon component which overrides the default titleId prop
 * This is needed because of hydration mismatch issues during (SSR) Server Side Rendering
 *
 * @param {FontAwesomeIconProps} props - Props to passthrough to the FontAwesomeIcon component
 * @returns {FontAwesomeIcon} The patched FontAwesomeIcon component with a overwritten titleId
 */
const FontAwesomeIcon = (props: FontAwesomeIconProps) => {
  // titleId as generated internally within FontAwesomeIcon produces a different version in client/server
  // To fix this problem, we can pass it in manually using useId which will produce the same value on client and server
  // Fixing upstream is challenging as they don't accept PRs and all the open source code is the built/minified code, patching is far easier here
  const titleId = useId();

  return <OriginalFontAwesomeIcon {...{ titleId, ...props }} />;
};

export { FontAwesomeIcon };
export * from "@fortawesome/react-fontawesome";
