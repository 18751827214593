const usePreferredUserColorTheme = () => {
  let preferredColorScheme = "light";
  let isPrefersDark = false;

  if (typeof window !== "undefined") {
    isPrefersDark = window?.matchMedia("(prefers-color-scheme: dark)")?.matches;
  }

  const isPrefersLight = !isPrefersDark;

  if (isPrefersDark) {
    preferredColorScheme = "dark";
  }

  return { preferredColorScheme, isPrefersDark, isPrefersLight };
};

export { usePreferredUserColorTheme };
