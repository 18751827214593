import { Global } from "@emotion/react";
import type { SerializedStyles } from "@emotion/react";

import * as base from "@aviary/styles/aviaryNormalize.styles";

interface Props {
  /*
   * Allows you to pass in your own custom global styles
   */
  customStyles?: SerializedStyles;
  /*
   * Toggles a white background instead of muted blue
   */
  isWhiteBackground?: boolean;
  /*
   * Disables normalization (ONLY FOR USE WITHIN ADMIN)
   * SERIOUSLY, THIS PAINS ME TO ADD
   * Only pigeons can use this
   */
  noNormalization?: boolean;
}

const AviaryGlobalStyles = ({
  customStyles,
  isWhiteBackground,
  noNormalization,
  ...rest
}: Props) => {
  const globalStyles = [
    base.normalize,
    base.aviaryBaseStyles,
    isWhiteBackground && base.whiteBackground,
    customStyles,
  ];

  if (noNormalization) return null;

  return <Global styles={globalStyles} {...rest} />;
};

export { AviaryGlobalStyles };
