import { css, type Theme } from "@emotion/react";

import { hexToRgba } from "@styles/emotion-styles/helpers";

import * as colors from "./colors";
import { borderWidth } from "./dimensions";

export const controlBase = css`
  appearance: none;
  box-shadow: none;

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.4;
  vertical-align: top;

  color: ${colors.dark};
  border: ${borderWidth} solid transparent;
  border-radius: 4px;
  font-size: 1rem;

  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
`;

export const cardShadow = (theme: Theme) => css`
  box-shadow: 0px 1px 2px ${hexToRgba(theme.text.body, 0.25)};
`;

const modalValues = {
  offsetX: 0,
  offsetY: "2px",
  blurRadius: "4px",
  spreadRadius: 0,
};

export const modalStylesObject = (theme: Theme) => {
  return {
    boxShadow: `${modalValues.offsetX} ${modalValues.offsetY} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${hexToRgba(theme.surface.overlayBackdrop, 0.2)}`,
  };
};

export const modalHeader = (theme: Theme) => css`
  box-shadow: ${modalValues.offsetX} ${modalValues.offsetY} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${hexToRgba(theme.surface.overlayBackdrop, 0.2)};
`;

export const modalFooter = (theme: Theme) => css`
  box-shadow: ${modalValues.offsetX} ${`-${modalValues.offsetY}`} ${modalValues.blurRadius}
    ${modalValues.spreadRadius} ${hexToRgba(theme.surface.overlayBackdrop, 0.2)};
`;

export const footerShadow = (theme: Theme) => css`
  box-shadow: 0px -3px 8px ${hexToRgba(theme.text.body, 0.25)};
`;

export const popoverShadow = (theme: Theme) => css`
  box-shadow: 0px 3px 8px ${hexToRgba(theme.text.body, 0.25)};
`;
