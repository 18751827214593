enum Size {
  THUMBNAIL = "thumbnail",
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  XLARGE = "xlarge",
  NORMAL = "normal",
}

export { Size };
