import type { ReactNode, Dispatch, SetStateAction } from "react";
import { createContext, useState } from "react";

enum GlobalContextUserRole {
  CLERK = "clerk",
  PATIENT = "patient",
  PRACTITIONER = "practitioner",
  ROLELESS = "roleless",
  UNAUTHENTICATED = "unauthenticated",
}
interface GlobalContextType {
  adminImpersonating?: boolean;
  country: string;
  role: GlobalContextUserRole;
  setRole?: Dispatch<SetStateAction<GlobalContextUserRole>>;
  isEmerson?: boolean;
  e2e: boolean;
  iterableApiKey?: string;
  rudderstackJsApiWriteKey?: string;
  rudderstackDataPlaneUrl?: string;
  isFs2Release?: boolean;
}

const GlobalContextDefault: GlobalContextType = {
  adminImpersonating: false,
  country: "United States",
  role: GlobalContextUserRole.UNAUTHENTICATED,
  isEmerson: false,
  e2e: false,
};

const GlobalContext = createContext<GlobalContextType>(GlobalContextDefault);

interface Props {
  adminImpersonating?: boolean;
  country: string;
  role: GlobalContextUserRole;
  isEmerson?: boolean;
  e2e: boolean;
  children: ReactNode;
  iterableApiKey?: string;
  rudderstackJsApiWriteKey?: string;
  rudderstackDataPlaneUrl?: string;
  isFs2Release?: boolean;
}

const GlobalContextProvider = ({
  children,
  adminImpersonating,
  role,
  country,
  isEmerson,
  e2e,
  iterableApiKey,
  rudderstackJsApiWriteKey,
  rudderstackDataPlaneUrl,
  isFs2Release,
}: Props) => {
  const [stateRole, setRole] = useState(role);

  return (
    <GlobalContext.Provider
      value={{
        country,
        adminImpersonating,
        role: stateRole,
        setRole,
        isEmerson,
        e2e,
        iterableApiKey,
        rudderstackJsApiWriteKey,
        rudderstackDataPlaneUrl,
        isFs2Release,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextUserRole, GlobalContextProvider, GlobalContextDefault };
export type { GlobalContextType };
