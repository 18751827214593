import type { ReactChild } from "react";
import { createContext } from "react";

import type { ToastType } from "./toastTypes";

interface ToastOptions {
  autoDismiss?: boolean;
  autoDismissTimeout?: number;
  toastId?: string;
  isCloseable?: boolean;
  onClose?: () => void;
}

interface ToastData {
  makeToast: (status: ToastType, content: ReactChild | string, options?: ToastOptions) => void;
  removeToast?: (toastId: string) => void;
}

const ToastContext = createContext<ToastData>(null);

export type { ToastData, ToastOptions };
export { ToastContext };
